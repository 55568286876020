import React from 'react';
import HFormUser from "global/components/Page/Forms/HFormUser";
import WithNavigate from "global/components/Page/Forms/WithNavigate";
import apiRuta from "global/utils/funRuta";
import ApiGl from "global/ApiGlobal";
import _menu from 'modMenu/_menu';
import HLink from "./HLinkMenu";
class HMenuHor extends HFormUser {
    constructor(props) {
        super(props);
        this.data = props;
        super.addMenu(_menu)
    }
    menuClick = (stTitulo, stPath, objPar) => {
        apiRuta.toRutaFirst(this, stTitulo, stPath, objPar)
    }
    render() {

        return (<>{this.fuMenu(_menu.arMenu)}</>)
    }
    fuMenu(lMenu) {
        return (
            <aside className="left-sidebar  with-horizontal">
                <div>
                    <nav className="sidebar-nav scroll-sidebar container-fluid">
                        <ul id="sidebarnav" className="in">
                            {lMenu && lMenu.map((regNiv1, index) => {
                                return (
                                    <div key={"div331_" + index}>
                                        <li className="nav-small-cap">
                                            <i className="ti ti-dots nav-small-cap-icon fs-4"></i>
                                            <span className="hide-menu">{regNiv1.titulo}</span>
                                        </li>
                                        <li key={"niv1li_" + index} className="sidebar-item selectedxx">
                                            <HLink key={"niv1_" + index}
                                                tituloArbol={regNiv1.titulo}
                                                to={regNiv1.ruta}
                                                btClickSelect={this.menuClick}
                                                icon={regNiv1.icon}
                                                label={regNiv1.titulo}
                                                nivel="2" />
                                            {regNiv1.menNiv && (<ul key={"niv2ul_" + index} aria-expanded="false" className="collapse first-level">
                                                {regNiv1.menNiv.map((regNiv2, index) => {
                                                    return (<li key={"niv2li_" + index} className="sidebar-item selectedxx">
                                                        <HLink key={"niv2_" + index}
                                                            tituloArbol={regNiv2.titulo}
                                                            to={regNiv2.ruta}
                                                            btClickSelect={this.menuClick}
                                                            icon={regNiv2.icon}
                                                            label={regNiv2.titulo}
                                                            nivel="2" />
                                                        {regNiv2.menNiv && (<ul key={"niv3ul_" + index}aria-expanded="false" className="collapse first-level">
                                                            {regNiv2.menNiv.map((regNiv3, index) => {
                                                                return (
                                                                    <li key={"niv3li_" + index} className="sidebar-item">
                                                                        <HLink key={"niv3_" + index}
                                                                            tituloArbol={regNiv3.titulo}
                                                                            to={regNiv3.ruta}
                                                                            btClickSelect={this.menuClick}
                                                                            icon={regNiv3.icon}
                                                                            label={regNiv3.titulo}
                                                                            nivel="3" />
                                                                    </li>
                                                                )
                                                            })}
                                                        </ul>)}
                                                    </li>
                                                    )
                                                })
                                                }
                                            </ul>)}
                                        </li></div>)
                            })}
                        </ul>
                    </nav>
                </div>
            </aside>
        )
    }

}
export default WithNavigate(HMenuHor);

