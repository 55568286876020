import React, { Component } from 'react';
import WithNavigate from "global/components/Page/Forms/WithNavigate";
import HFormUser from "global/components/Page/Forms/HFormUser";


class HeaderRuta extends HFormUser {
    render = () => {
        //if (this.context.dataUser.arrUrl.length < 1)  return (<></>)
        return (
            <div className="card" >
                <div className="card-body px-4 py-3">
                    <div className="row align-items-center">
                        <div className="col-9">
                            {/* <h2>{this.usuarioVar.tituloContainer || 'Harrys Informática'}</h2> */}
                            <ul className="breadcrumb">
                                <li className="breadcrumb-item">
                                    <a href="index.html" onClick={() => this.props.navigate("/")}>
                                        <i className="zmdi zmdi-home"></i>
                                        Inicio</a></li>
                                {this.context.dataUser.arrUrl.map((usrData, iLin) => {
                                    if (usrData.titulo != null)
                                        return (
                                            <li className="breadcrumb-item" key={"uie_"+iLin}>
                                                <a style={{ cursor: 'pointer' }}
                                                    key={"kjk" + iLin}
                                                    onClick={() => this.saltarRutaContext(this, iLin)}> {usrData.titulo} </a>
                                            </li>)
                                })}
                            </ul>
                        </div>
                        <div className="col-3 card-actions cursor-pointer ms-auto d-flex button-group">
                            <a className="card-actions cursor-pointer ms-auto d-flex button-group btn btn-danger"
                                style={{ cursor: 'pointer' }}
                                onClick={() => this.saltarVolverContext(this)}>
                                <i className="fa fa-arrow-left"></i>
                            </a>
                        </div>
                    </div>
                </div>
            </div>
        )
    }
}
export default WithNavigate(HeaderRuta);
